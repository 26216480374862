import HeadLine from "../../HeadLine";

// images
import playStoreIcon from "../../../assets/images/playStore.png";
import appImage from "../../../assets/images/mobileApp.png";

export default function Download() {
  return (
    <>
      <section id="download" className="relative pt-16 dark:bg-jacarta-800">
        <HeadLine
          text="Downloads"
          classes="font-display text-jacarta-700 mb-4 text-center text-3xl dark:text-white"
        />

        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
        <div className="container">
          <div className="flex flex-col items-center space-y-10 lg:flex-row lg:space-y-0 lg:space-x-4">
            <div className="mb-10  lg:w-[50%]">
              <div className="text-center lg:text-left lg:w-[75%]">
                <h2 className="mb-6 font-display text-xl text-jacarta-700 dark:text-white lg:pr-4">
                  Download our app
                  <span className="text-jacarta-300 ml-2">
                    to stream 10,000+ live channels, movies and series
                  </span>
                </h2>
                <div className="inline-flex space-x-4">
                  <AppleStoreBtn />
                  <PlayStoreBtn />
                </div>
              </div>
            </div>

            <div className="lg:w-[50%]">
              <img src={appImage} className="inline-block" alt="app" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const PlayStoreBtn = () => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.devcoder.devoiptvplayer"
      className="flex items-center rounded-full bg-white py-3 px-6 text-center font-semibold text-jacarta-500 shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
      target="_blank"
      without
      rel="noreferrer"
    >
      <img
        src={playStoreIcon}
        alt="google icon"
        className="mr-2 h-5 w-5 fill-jacarta-700 dark:fill-white"
      />
      Google play
    </a>
  );
};

const AppleStoreBtn = () => {
  return (
    <a
      href="https://apps.apple.com/in/app/smarters-player-lite/id1628995509"
      className="group flex items-center rounded-full bg-white py-3 px-6 text-center font-semibold text-jacarta-500 shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
      target="_blank"
      without
      rel="noreferrer"
    >
      <svg className="icon mr-2 -mt-1 inline-block h-5 w-5 fill-jacarta-500 transition-colors group-hover:fill-white">
        <use xlinkHref="/icons.svg#icon-apple"></use>
      </svg>
      App Store
    </a>
  );
};
