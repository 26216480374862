import React from "react";
import ReactGA from "react-ga4";
import ReactDOM from "react-dom/client";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//Initialize GA4
ReactGA.initialize("G-CLPH14G0HP");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals(() => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
});
