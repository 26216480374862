import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const MblNavbar = ({ theme }) => {
  const [navItemValue, setNavItemValue] = useState(1);
  const [navText, setnavText] = useState("");

  const handleItemDropdown = (e) => {
    const target = e.target.closest("li");

    if (!target.classList.contains("show")) {
      target.classList.add("show");
    } else {
      target.classList.remove("show");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        // dispatch(closeMblMenu());
      }
    });

    localStorage.setItem("navItemValue", 1);

    const value = localStorage.getItem("navItemValue");
    setNavItemValue(+value);

    if (navItemValue > 0 && navItemValue <= 8) {
      setnavText("home");
    } else if (navItemValue > 8 && navItemValue <= 24) {
      setnavText("pages");
    } else if (navItemValue > 24 && navItemValue <= 30) {
      setnavText("resources");
    } else if (navItemValue === 32) {
      setnavText("collection");
    } else if (navItemValue === 33) {
      setnavText("create");
    } else if (navItemValue === 34) {
      setnavText("affiliate");
    }
  }, [navItemValue]);

  return (
    <div
      className={
        false
          ? "js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent nav-menu--is-open"
          : "js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent"
      }
    >
      <MobileMenuHeader />
      {/* <!-- Primary Nav --> */}
      <nav className="navbar w-full">
        <ul className="flex flex-col lg:flex-row">
          <li className="group">
            <HashLink
              // onClick={() => {
              //   localStorage.setItem("navItemValue", 1);
              // }}
              to="/#home"
            >
              <button
                className={
                  "text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5"
                }
              >
                <span className={navText === "create" ? "text-accent" : ""}>
                  Home
                </span>
              </button>
            </HashLink>
          </li>
          <li className="group">
            <HashLink
              // onClick={() => {
              //   localStorage.setItem("navItemValue", 1);
              // }}
              to="/#channels"
            >
              <button
                className={
                  "text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5"
                }
              >
                <span className={navText === "create" ? "text-accent" : ""}>
                  Channels
                </span>
              </button>
            </HashLink>
          </li>
          <li className="group">
            <HashLink
              // onClick={() => {
              //   localStorage.setItem("navItemValue", 1);
              // }}
              to="/#pricing"
            >
              <button
                className={
                  "text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5"
                }
              >
                <span className={navText === "create" ? "text-accent" : ""}>
                  Pricing
                </span>
              </button>
            </HashLink>
          </li>

          <li className="group">
            <HashLink
              // onClick={() => {
              //   localStorage.setItem("navItemValue", 1);
              // }}
              to="/#download"
            >
              <button
                className={
                  "text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5"
                }
              >
                <span className={navText === "create" ? "text-accent" : ""}>
                  Downloads
                </span>
              </button>
            </HashLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MblNavbar;

const MobileMenuHeader = () => {
  return (
    <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
      <a>
        <img
          src="/images/logo.png"
          className="max-h-7 dark:hidden"
          alt="Xhibiter | NFT Marketplace"
        />

        <img
          src="/images/logo_white.png"
          alt="Xhibiter | NFT Marketplace"
          className="max-h-7 dark:block hidden"
        />
      </a>

      {/* <!-- Menu Close --> */}
      <button className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
        >
          <path fill="none" d="M0 0h24v24H0z"></path>
          <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
        </svg>
      </button>
    </div>
  );
};
