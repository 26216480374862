import React from "react";

const index = () => {
  return (
    <div className="pt-[5.5rem] lg:pt-24">
      <section className="dark:bg-jacarta-800 relative py-16 md:py-24">
        <div class="container">
          <h1 class="font-display text-jacarta-700 text-center text-4xl font-medium dark:text-white">
            Terms Of Service
          </h1>

          <div class="article-content mx-auto max-w-[48.125rem]">
            <h2 class="text-base">Introduction</h2>
            <p>
              Welcome to our Rahul IPTV service! Before you proceed, please
              carefully read and understand the following terms and conditions
              that govern the use of Rahul IPTV service. By subscribing to our
              service, you agree to comply with and be bound by these terms.
            </p>
            <h2>Service Description:</h2>
            <p>
              Our IPTV service provides access to a variety of television
              channels and content through internet streaming.
            </p>
            <h2>Subscription:</h2>
            <p>
              Subscribers must provide accurate and complete information during
              the subscription process. Subscribers are responsible for
              maintaining the confidentiality of their account information and
              are liable for any unauthorized access.
            </p>
            <h2>Payment and Billing:</h2>
            <p>
              Subscribers agree to pay the subscription fee as specified during
              the signup process. Payments are non-refundable, except as
              explicitly stated in the return policy.
            </p>

            <h2>Return Policy:</h2>
            <p>
              Our return policy allows for a 30-day money-back guarantee. If you
              encounter issues with our service, please contact our support team
              within 30 days of the initial subscription for a refund. Refunds
              will only be processed once our support team confirms the issue.
              If the issue cannot be resolved by our team, a full refund will be
              issued.
            </p>

            <h2>Refund Process:</h2>
            <p>
              Refunds will be processed through the original payment method. It
              may take a certain number of business days for the refund to be
              reflected in your account.
            </p>

            <h2>Service Availability:</h2>
            <p>
              Rahul IPTV service availability may vary based on geographic
              location and internet connectivity. We strive to maintain a high
              level of service availability, but interruptions may occur due to
              maintenance or technical issues.
            </p>

            {/* <h2>Acceptable Use:</h2>
            <p>
              Subscribers agree to use the Rahul IPTV service for personal,
              non-commercial use only. Any unauthorized distribution or sharing
              of login credentials is strictly prohibited.
            </p> */}

            <h2>Content Rights:</h2>
            <p>
              The content provided through Rahul IPTV service is protected by
              copyright laws. Subscribers are prohibited from reproducing,
              distributing, or modifying the content without explicit
              permission.
            </p>

            <h2>Changes to Terms and Service:</h2>
            <p>
              We reserve the right to modify these terms and conditions or the
              Rahul IPTV service at any time. Subscribers will be notified of
              significant changes in advance.
            </p>

            <h2>Termination: </h2>
            <p>
              We reserve the right to terminate or suspend a subscriber's
              account for violation of these terms and conditions.
            </p>

            <p>
              By subscribing to Rahul IPTV service, you acknowledge that you
              have read, understood, and agreed to these terms and conditions.
              If you have any questions or concerns, please contact our customer
              support team. Thank you for choosing Rahul IPTV service!{" "}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default index;
