import React from "react";

// images
import gradient from "../../assets/images/gradient.jpg";
import gradient_dark from "../../assets/images/gradient_dark.jpg";

function Hero() {
  return (
    <section id="home" className="hero relative py-20 md:pt-32">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
        <img src={gradient} alt="gradient" />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img src={gradient_dark} alt="gradient dark" />
      </picture>

      <div className="container">
        <div className="mx-auto max-w-2xl pt-24 text-center">
          <h1 className="mb-2 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl">
            <span className="animate-gradient">Rahul IPTV</span>
          </h1>

          <p className="mb-8 text-center text-lg dark:text-jacarta-200">
            Experience ultimate home entertainment with Rahul IPTV's
            budget-friendly plan, offering over 130,000+ movies and 10,000+ live
            TV channels. Enjoy the latest and classic movie collections along
            with exclusive content from major OTT platforms at an unbeatable
            price.
          </p>
        </div>

        <div className="w-full sm:flex sm:space-x-4 xl:pl-[20%] xl:pr-[20%]">
          <Card
            number="10,000+"
            numberColor="text-[#8DD059]"
            title="Live Channels"
          />

          <Card number="130,000+" numberColor="text-[#737EF2]" title="Movies" />

          <Card number="5000+" numberColor="text-[#F35BC7]" title="Series" />
        </div>
      </div>
    </section>
  );
}

export default Hero;

const Card = ({ number, numberColor, title }) => {
  return (
    <div className="mb-4 flex-1 rounded-2lg bg-white p-4 text-center dark:bg-white/[.15]">
      <span className={`block font-display text-3xl ${numberColor}`}>
        {number}
      </span>
      <span className="block font-display text-sm text-jacarta-500 dark:text-white">
        {title}
      </span>
    </div>
  );
};
